import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useEffect, useState } from "react";
import { message } from "antd";
import axios from "axios";
import Home from "./pages/Home";
import Register from "./pages/Register";
import Login from "./pages/Login";
import ForgotPass from "./pages/ForgotPass";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Dashboard from "./user/Dashboard";
import ProtectedRoute from "./components/ProtectedRoute";
import PublicRoute from "./components/PublicRoute";
import ResetPassword from "./pages/ResetPassword";
import AdminDashboard from "./admin/AdminDashboard";
import AdminUsers from "./admin/AdminUsers";
import EditUser from "./admin/EditUser";
import AdminPayments from "./admin/AdminPayments";
import Terms from "./pages/Terms";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import RefundPolicy from "./pages/RefundPolicy";
import AdminBulkEmail from "./admin/AdminBulkEmail";
import AdminLogin from "./admin/AdminLogin";
import Search from "./pages/Search";
import AdminProduct from "./admin/AdminProduct";
import AdminAddProduct from "./admin/AdminAddProduct";
import AdminEditProduct from "./admin/AdminEditProduct";
import AdminAddBrand from "./admin/AdminAddBrand";
import AdminOrder from "./admin/AdminOrder";
import AdminGallery from "./admin/AdminGallery.js";
import ProductInfo from "./pages/ProductInfo";
import Cart from "./pages/Cart";
import Checkout from "./pages/Checkout";
import TrackOrder from "./pages/TrackOrder";
import Orders from "./user/Orders";
import Address from "./user/Address";
import Account from "./user/Account";
import ViewOrder from "./user/ViewOrder";
import AdminViewOrder from "./admin/AdminViewOrder";
import AdminAddCoupon from "./admin/AdminAddCoupon";
import PhotoUpload from "./admin/PhotoUpload";
import AdminQueries from "./admin/AdminQueries";
import Wallet from "./user/Wallet";
import AdminBarcodePayments from "./admin/AdminBarcodePayments";
import ProductPage from "./pages/ProductPage";
import GiveAway from "./pages/GiveAway";
import Loader from "./pages/Loader";
import Maintenance from "./pages/Maintenance";
import AdminNotification from "./admin/AdminNotification";
import AdminCashback from "./admin/AdminCashback";
import Test from "./pages/Test";
import Query from "./user/Query.js";

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    function handleLoad() {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
    window.onload = handleLoad;
    return () => {
      window.onload = null;
    };
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        {/* pages */}
        <Route path="/" element={<Home />} />
        <Route
          path="/register"
          element={
            <PublicRoute>
              <Register />
            </PublicRoute>
          }
        />
        <Route
          path="/login"
          element={
            <PublicRoute>
              <Login />
            </PublicRoute>
          }
        />
        <Route path="/forgot-password" element={<ForgotPass />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/games" element={<ProductPage />} />
        <Route path="/search" element={<Search />} />
        <Route path="/product/:name?" element={<ProductInfo />} />
        <Route path="/contact" element={<Contact />} />
        {/* <Route path="/give-away" element={<GiveAway />} /> */}
        {/* <Route path="/cart" element={<Cart />} /> */}
        {/* <Route path="/checkout" element={<Checkout />} /> */}
        {/* <Route path="/about" element={<About />} /> */}
        {/* <Route path="/track-order" element={<TrackOrder />} /> */}
        {/* <Route path="/dev" element={<Contact />} /> */}
        {/* <Route path="/checking-status" element={<CheckStatus />} /> */}
        {/* <Route path="/faq" element={<FrequentlyAskedQuestions />} /> */}
        <Route
          path="/orders"
          element={
            <ProtectedRoute>
              <Orders />
            </ProtectedRoute>
          }
        />
        <Route
          path="/wallet"
          element={
            <ProtectedRoute>
              <Wallet />
            </ProtectedRoute>
          }
        />
        <Route
          path="/query"
          element={
            <ProtectedRoute>
              <Query />
            </ProtectedRoute>
          }
        />
        <Route
          path="/address"
          element={
            <ProtectedRoute>
              <Address />
            </ProtectedRoute>
          }
        />
        <Route
          path="/my-account"
          element={
            <ProtectedRoute>
              <Account />
            </ProtectedRoute>
          }
        />
        <Route
          path="/view-order/:orderId?"
          element={
            <ProtectedRoute>
              <ViewOrder />
            </ProtectedRoute>
          }
        />
        {/* <Route path="/service" element={<Service />} /> */}
        <Route path="/terms" element={<Terms />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/refund-policy" element={<RefundPolicy />} />
        {/* ======================== USER PAGES =============================== */}
        {/* ========== EMAIL VERIFY */}
        <Route
          path="/user-dashboard"
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        />
        {/* ======================== USER PAGES =============================== */}
        {/* ======================== ADMIN PAGES =============================== */}
        <Route
          path="/admin-login"
          element={
            <PublicRoute>
              <AdminLogin />
            </PublicRoute>
          }
        />
        <Route
          path="/admin-dashboard"
          element={
            <ProtectedRoute>
              <AdminDashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin-orders"
          element={
            <ProtectedRoute>
              <AdminOrder />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin-view-order/:orderId?"
          element={
            <ProtectedRoute>
              <AdminViewOrder />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin-products"
          element={
            <ProtectedRoute>
              <AdminProduct />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin-add-product"
          element={
            <ProtectedRoute>
              <AdminAddProduct />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin-edit-product/:id?"
          element={
            <ProtectedRoute>
              <AdminEditProduct />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin-add-brand"
          element={
            <ProtectedRoute>
              <AdminAddBrand />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin-test"
          element={
            <ProtectedRoute>
              <PhotoUpload />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin-add-coupon"
          element={
            <ProtectedRoute>
              <AdminAddCoupon />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin-users"
          element={
            <ProtectedRoute>
              <AdminUsers />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin-edit-user/:id?"
          element={
            <ProtectedRoute>
              <EditUser />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin-payments"
          element={
            <ProtectedRoute>
              <AdminPayments />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin-payment"
          element={
            <ProtectedRoute>
              <AdminBarcodePayments />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin-bulk-email"
          element={
            <ProtectedRoute>
              <AdminBulkEmail />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin-queries"
          element={
            <ProtectedRoute>
              <AdminQueries />
            </ProtectedRoute>
          }
        />
        {/* <Route
          path="/admin-notification"
          element={
            <ProtectedRoute>
              <AdminNotification />
            </ProtectedRoute>
          }
        /> */}
        <Route
          path="/admin-cashback"
          element={
            <ProtectedRoute>
              <AdminCashback />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin-gallery"
          element={
            <ProtectedRoute>
              <AdminGallery />
            </ProtectedRoute>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
